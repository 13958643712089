export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      'Pioneer Package': 'Pioneer Package',
      'Total cost': 'Total cost',
      Confirm: 'Confirm',
      Cancel: 'Cancel',
      'Buy Hash': 'Buy Hash',
      Total: 'Total',
      'BUY NOW': 'BUY NOW',
      'TOPUP WALLET': 'TOPUP WALLET',
      'Link Copied': 'Link Copied',
      'Hash Mining Pool': 'Hash Mining Pool',
      scanQrCode: 'Scan this QR code to join us.',
      allRightsReserved: '© Pennywhale. All Rights Reserved.',
      'to join us.': 'to join us.',
      Invite: 'Invite',
      Upgrade: 'Upgrade',
      Purchase: 'Purchase',
      Deposit: 'Deposit',
      Withdraw: 'Withdraw',
      withdraw: 'Withdraw',
      Collection: 'Collection',
      'No transaction history': 'No transaction history',
      'Transaction History': 'Transaction History',
      deposit: 'Deposit',
      from: 'FROM',
      Campaign: 'Campaign',
      Wallet: 'wallet',
      Community: 'Community',
      Rank: 'Rank',
      'Min Hashrate': 'Min Hashrate',
      'Pennywhale Holding': 'Pennywhale Holding',
      'Referral Amount': 'Referral Amount',
      Friends: 'Friends',
      Previous: 'Previous',
      Next: 'Next',
      Boost: 'Boost',
      Task: 'Task',
      Treasure: 'Treasure',
      'Starter Pool': 'Starter Pool',
      'Poseidon Pool': 'Poseidon Pool',
      'Neptune Pool': 'Neptune Pool',
      'Varuna Pool': 'Varuna Pool',
      Earnings: 'Earnings',
      Capacity: 'capacity',
      status: 'status',
      Level: 'Level',
      miningCapacity: 'miningCapacity',
      minningData: 'minningData',
      Claim: 'Claim',
      accumulatedPoints: 'accumulatedPoints',
      lastHarvestClicked: 'lastHarvestClicked',
      poolData: 'poolData',
      upgradeDetail: 'upgradeDetail',
      'Earnings up to': 'Earnings up to',
      'Capacity up to': 'Capacity up to',
      'Upgraded from level': 'Upgraded from level',
      'Upgrade Mining': 'Upgrade Mining',
      Cost: 'Cost',
      EN: 'EN',
      ZH: '中文',
      Event: 'Event',
      'Earn More Points': 'Earn More Points',
      'Guess the Bitcoin Leaderboard': 'Guess the Bitcoin Leaderboard',
      'Daily task': 'Daily task',
      'Task list': 'Task list',
      'Daily check-in': 'Daily check-in',
      'Watch youtube daily': 'Watch youtube daily',
      'Retweet X': 'Retweet X',
      "Watch PennyWhale's Journey (Official Video)": "Watch PennyWhale's Journey (Official Video)",
      '5 Star Review on TON app': '5 Star Review on TON app',
      'Follow Telegram Group': 'Follow Telegram Group',
      'Follow Facebook': 'Follow Facebook',
      'Follow Medium': 'Follow Medium',
      'Follow Tiktok': 'Follow Tiktok',
      'Follow Instagram': 'Follow Instagram',
      'Follow LinkedIn Company': 'Follow LinkedIn Company',
      'Follow our X': ' Follow our X',
      'Follow Telegram Group Annoucement': ' Telegram Group Annoucement',
      'Invite a friend': 'Invite a friend',
      'Invite a friend with Telegram Premium': ' Invite a friend with Telegram Premium',
      'Pennywhale Season 2 Leaderboard': 'Pennywhale Season 2 Leaderboard',
      Join: 'Join',
      Upload: 'Upload',
      'Logging into the game daily without skipping a day':
        'Logging into the game daily without skipping a day',
      'Purchase Hashrate': 'Purchase Hashrate',
      'Purchase Shell Product': 'Purchase Shell Product',
      'Asset Vault': 'ASSET VAULT',
      Bronze: 'Bronze',
      Silver: 'Silver',
      Gold: 'Gold',
      Platinum: 'Platinum',
      Diamond: 'Diamond',
      Epic: 'Epic',
      Legendary: 'Legendary',
      Master: 'Master',
      GrandMaster: 'GrandMaster',
      Lord: 'Lord',
      'Your Balance': 'Your Balance',
      'Multi tap': 'Multi tap',
      'Energy limit': 'Energy limit',
      'Premium Booster': 'Premium Booster',
      'Refill full energy': 'Refill full energy',
      availables: 'availables',
      BUY: 'BUY',
      'Tap assistant': 'Tap assistant',
      'Energy Booster': 'Energy Booster',
      'Recharge your energy to the maximum': 'Recharge your energy to the maximum',
      'Go ahead': 'Go ahead',
      Free: 'Free',
      'Increase the amount of energy': 'Increase the amount of energy',
      'energy points for level': 'energy points for level',
      'Increase the amount of coins you can earn per tap':
        'Increase the amount of coins you can earn per tap',
      'tap for level': 'tap for level',
      'Premium Energy': 'Premium Energy',
      REFILL: 'REFILL',
      'Premium Tap Assistant': 'Premium Tap Assistant',
      'Assist to tap the maximum energy': 'Assist to tap the maximum energy',
      'Tap Assistant': 'Tap Assistant',
      Assist: 'Assist',
      'Energy Limit': 'Energy Limit',
      Direct: 'Direct',
      Recent: 'Recent',
      'Guess the BTC price': 'Guess the BTC price',
      'Subscribe our Youtube': 'Subscribe our Youtube',
    },
    zh: {
      'Pioneer Package': '购买配套',
      '1 USDT = 1 Th/s': '1 USDT = 1 Th/s',
      'Total cost': '总费用',
      Confirm: '确认',
      Cancel: '取消',
      'Buy Hash': '购买哈希',
      Total: '总计',
      'BUY NOW': '立即购买',
      'TOPUP WALLET': '充值钱包',
      'Link Copied': '链接已复制',
      'Hash Mining Pool': '哈希矿池',
      'Scan this QR code': '扫描此二维码',
      allRightsReserved: '© Pennywhale. 版权所有。',
      'to join us.': '加入我们。',
      Invite: '邀请',
      Upgrade: '升级',
      Purchase: '购买',
      Deposit: '存款',
      Withdraw: '取款',
      Collection: '收藏',
      'No transaction history': '无交易历史',
      'Transaction History': '交易历史',
      deposit: '存款',
      withdraw: '取款',
      from: '来自',
      Campaign: '活动',
      Wallet: '钱包',
      Community: '社区',
      Rank: '排名',
      'Min Hashrate': '最小算力',
      'Pennywhale Holding': 'Pennywhale 持有量',
      'Referral Amount': '推荐数量',
      Friends: '朋友',
      Previous: '上一页',
      Next: '下一页',
      Boost: '提升',
      Task: '任务',
      Treasure: '宝藏',
      'Starter Pool': '初级矿池',
      'Poseidon Pool': '波塞冬矿池',
      'Neptune Pool': '海王星矿池',
      'Varuna Pool': '瓦鲁纳矿池',
      Earnings: '收益',
      Capacity: '容量',
      status: '状态',
      Level: '等级',
      miningCapacity: '挖矿容量',
      minningData: '挖矿数据',
      Claim: '领取',
      accumulatedPoints: '累计点数',
      lastHarvestClicked: '上次收获点击',
      poolData: '矿池数据',
      upgradeDetail: '升级详情',
      'Earnings up to': '收益高达',
      'Capacity up to': '容量高达',
      'Upgraded from level': '从等级升级',
      'Upgrade Mining': '升级挖矿',
      Cost: '费用',
      EN: 'EN',
      ZH: '中文',
      Event: '活动',
      'Earn More Points': '赚取更多积分',
      'Guess the Bitcoin Leaderboard': '猜比特币排行榜',
      'Daily task': '每日任务',
      'Task list': '任务列表',
      'Daily check-in': '每日签到',
      'Watch youtube daily': '每日观看 YouTube',
      'Retweet X': '转推 X',
      "Watch PennyWhale's Journey (Official Video)": '观看 PennyWhale 的旅程（官方视频）',
      '5 Star Review on TON app': '在 TON 应用上给出 5 星评价',
      'Follow Telegram Group': '关注 Telegram 群组',
      'Follow Facebook': '关注 Facebook',
      'Follow Medium': '关注 Medium',
      'Follow Tiktok': '关注 Tiktok',
      'Follow Instagram': '关注 Instagram',
      'Follow LinkedIn Company': '关注 LinkedIn 公司',
      'Follow our X': '关注我们的 X',
      'Follow Telegram Group Annoucement': '关注 Telegram 群组公告',
      'Invite a friend': '邀请朋友',
      'Invite a friend with Telegram Premium': '用 Telegram Premium 邀请朋友',
      'Pennywhale Season 2 Leaderboard': 'Pennywhale 第二季排行榜',
      Join: '加入',
      'Logging into the game daily without skipping a day': '每天登录游戏不间断',
      'Purchase Hashrate': '购买算力配套',
      'Purchase Shell Product': '购买游乐园配套',
      'Asset Vault': '资产库',
      Bronze: '青铜',
      Silver: '白银',
      Gold: '黄金',
      Platinum: '铂金',
      Diamond: '钻石',
      Epic: '史诗',
      Legendary: '传奇',
      Master: '大师',
      GrandMaster: '宗师',
      Lord: '领主',
      'Your Balance': '您的余额',
      'Multi tap': '多次点击',
      'Energy limit': '能量上限',
      'Premium Booster': '高级助推器',
      'Refill full energy': '补充满能量',
      availables: '可用的',
      BUY: '购买',
      'Tap assistant': '点击助手',
      'Energy Booster': '能量助推器',
      'Recharge your energy to the maximum': '将您的能量充满',
      'Go ahead': '继续',
      Free: '免费',
      'Increase the amount of energy': '增加能量数量',
      'energy points for level': '等级能量点数',
      'Increase the amount of coins you can earn per tap': '增加每次点击可赚取的金币数量',
      'tap for level': '等级点击',
      'Premium Energy': '高级能量',
      REFILL: '补充',
      'Premium Tap Assistant': '高级点击助手',
      'Assist to tap the maximum energy': '协助点击最大能量',
      'Tap Assistant': '点击助手',
      Assist: '协助',
      'Energy Limit': '能量上限',
      Direct: '直接',
      Recent: '最近',
      'Guess the BTC price': '猜比特币价格',
      'Subscribe our Youtube': '订阅我们的 YouTube',
    },
  },
}));
